import { Link, useNavigate } from 'react-router-dom';
import { AppBar, Toolbar, Box, Typography, Button } from '@mui/material';
import Logo from "../assets/logo.svg";
import { useAuth } from "./Auth"

const drawerWidth = 250;

const appbarStyle = {
  background: '#FFFFFF',
  // width: `calc(100% - ${drawerWidth}px)`,
  // ml: `${drawerWidth}px`,
};

function Nav() {
  const { teacher, logout } = useAuth();
  const navigate = useNavigate();

  const gotoLogin = () => {
    navigate("/login");
  };
  const gotoSignup = () => {
    navigate("/signup");
  };
  const gotoClassroom = () => {
    navigate("/classroom");
  };

  return (
    <AppBar position="fixed" style={appbarStyle} elevation={1} >
      <Toolbar>
        <Box component="img" sx={{ height: 32 }} alt="Logo" src={Logo} padding="10px" />
        <Typography style={{ textDecoration: "none" }} fontSize={18} fontWeight={500} color="black">
          {/* <Link to="/" style={{ textDecoration: "none", color:"black" }}> */}
          Teacher Dashboard
          {/* </Link> */}
        </Typography>

        <Box flexGrow={1} />
        {
          teacher ?
            <Typography color="black" fontSize={16} paddingRight="20px">
              Welcome, {teacher.firstName}
            </Typography> : <Box />
        }


        {!teacher ? [
          <Button onClick={gotoLogin}>Login</Button>,
          <Button onClick={gotoSignup}>Signup</Button>
        ] :
          <Button onClick={gotoClassroom}>Dashboard</Button>
        }
      </Toolbar>
    </AppBar>
  );
}

export default Nav;