import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Card, Divider, IconButton, MenuItem, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from '@mui/material';
import { DashboardDrawer } from '../DashboardDrawer';
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { useNavigate } from 'react-router-dom';
import { ClassroomI, ClassroomId, ReportCardCommentI, StudentI } from "../../../interfaces/interfaces";
import Api from "../../../Api";

const SchoolYear = [
  "2019/2020",
  "2020/2021",
  "2021/2022",
]

const ReportCardTerm = [
  "Progress-Report",
  "Winter",
  "Summer",
]

// const Students = [
//   { firstName: "Isaiah", lastName: "Ballah" },
//   { firstName: "Liam", lastName: "Bilbie" },
//   { firstName: "Yassin", lastName: "Al Hafiz" },
//   { firstName: "Jacob", lastName: "Zynomirski" },
//   { firstName: "Zach", lastName: "Zynomirski" },
// ]

export function ReportCardCommentsPage() {

  const [, setLoading] = React.useState(true);
  const [classrooms, setClassrooms] = React.useState<ClassroomI[]>([]);

  const [classroom, setClassroom] = React.useState<ClassroomI>();
  const [classroomName, setClassroomName] = React.useState<string>("");

  const [schoolYear, setSchoolYear] = React.useState(SchoolYear[SchoolYear.length - 1]);
  const [reportCardTerm, setReportCardTerm] = React.useState(ReportCardTerm[0]);

  const [newStudentFirstName, setNewStudentFirstName] = React.useState("");
  const [newStudentLastName, setNewStudentLastName] = React.useState("");

  React.useEffect(() => {
    setLoading(false);
  }, [])

  React.useEffect(() => {
    if (!classroom) return;
    setClassroomName(classroom.name);
  }, [classroom]);

  React.useEffect(() => {
    async function fetchClassrooms() {
      try {
        const _classrooms: ClassroomI[] = await Api.classroom.getClassrooms(schoolYear);
        setClassrooms(_classrooms);
      }
      catch (error) {
        console.log(error);
      }
    }

    fetchClassrooms();
  }, [schoolYear]);

  React.useEffect(() => {
    if (classrooms.length === 0) {
      setClassroom(undefined);
      setClassroomName("");
    }
  }, [classrooms]);


  function onClassroomNameChanged(event: SelectChangeEvent) {
    setClassroomName(event.target.value);
  }
  function onSchoolYearChanged(event: SelectChangeEvent) {
    setSchoolYear(event.target.value);
  }
  function onReportCardTermChanged(event: SelectChangeEvent) {
    setReportCardTerm(event.target.value);
  }
  function onFirstNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setNewStudentFirstName(event.target.value);
  }
  function onLastNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setNewStudentLastName(event.target.value);
  }


  // if (loading) return (
  //   <div>
  //     Loading ...
  //   </div>
  // );


  return (

    <Box
      minWidth="100vw" minHeight="100vh"
      bgcolor={"#F7F8FC"}
      display="flex" flexDirection="row"
    >
      <DashboardDrawer selected='Report Card Comments' />
      <Box flexGrow={1} display="flex" flexDirection={"column"} padding="30px">
        <Typography fontWeight={500} fontSize={24} paddingBottom="15px">Report Card Comments</Typography>

        <Box display="flex" flexDirection="row">
          <Box>
            <Typography fontWeight={500} fontSize={19} >School Year</Typography>
            <Select
              value={schoolYear}
              onChange={onSchoolYearChanged}
              size="small"
              style={{ width: "160px", minWidth: "100%", backgroundColor: "white" }}
            >
              {SchoolYear.map((value) => {
                return <MenuItem key={value} value={value} > {value} </MenuItem>
              })}
            </Select>
          </Box>

          <Box paddingLeft="30px">
            <Typography fontWeight={500} fontSize={19} >Classroom</Typography>
            <Select
              value={classroomName}
              onChange={onClassroomNameChanged}
              size="small"
              style={{ width: "160px", minWidth: "100%", backgroundColor: "white" }}
            >
              {classrooms.map((classroom: ClassroomI) => {
                return <MenuItem key={classroom._id} value={classroom.name} onClick={() => setClassroom(classroom)}> {classroom.name} </MenuItem>
              })}
            </Select>
          </Box>

          <Box paddingLeft="30px">
            <Typography fontWeight={500} fontSize={19} >Report Card Term</Typography>
            <Select
              value={reportCardTerm}
              onChange={onReportCardTermChanged}
              size="small"
              style={{ width: "200px", minWidth: "100%", backgroundColor: "white" }}
            >
              {ReportCardTerm.map((value) => {
                return <MenuItem key={value} value={value} > {value} </MenuItem>
              })}
            </Select>
          </Box>


        </Box>

        <Box flexGrow={1} paddingTop="15px">
          <Card style={{ width: "100%", height: "100%" }} elevation={0} variant="outlined">
            <Box padding="30px">

              {/* Students - Add a Student Field and Student list */}
              <Typography fontWeight={500} fontSize={19} >Students</Typography>
              <Box display="flex" flexDirection={"row"} alignItems={"center"} paddingBottom="10px">
                <Box width={"160px"}>
                  <TextField label="First Name" variant="outlined" size="small" fullWidth={true} margin="dense" onChange={onFirstNameChanged} value={newStudentFirstName} />
                </Box>
                <Box width={"160px"} paddingLeft={"10px"}>
                  <TextField label="Last Name" variant="outlined" size="small" fullWidth={true} margin="dense" onChange={onLastNameChanged} value={newStudentLastName} />
                </Box>
                <Box width={"160px"} paddingTop="4px" paddingLeft="10px">
                  <Button fullWidth variant="contained" style={{ backgroundColor: "#3751FF", textTransform: "none", height: "40px" }} onClick={() => { }}>
                    <Typography color="white" fontSize={12}>Add New Student</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box width={"100%"}>
              {/* Student List */}
              <Divider />
              {
                classroom ?
                  <StudentsList classroom={classroom} reportCardTerm={reportCardTerm} />
                  :
                  <Box />
              }
            </Box>
          </Card>
        </Box>

      </Box>
    </Box>
  );
}


interface ReportCardCommentStatus {
  status: String,
  color: String,
}




// export const DashboardDrawer: React.FC<{ selected: string }> = ({ selected }) => {
type StudentReportMap = { [_id: string]: ReportCardCommentI };

const StudentsList: React.FC<{ classroom: ClassroomI, reportCardTerm: string }> = ({ classroom, reportCardTerm }) => {
  const [students, setStudents] = React.useState<StudentI[]>([]);
  const [studentReportMap, setStudentReportMap] = React.useState<StudentReportMap>({});

  React.useEffect(() => {
    async function fetchStudents() {
      try {
        if (!classroom) return setStudents([]);
        const _students: StudentI[] = await Api.classroom.getStudents(classroom._id ?? "");
        const _comments: ReportCardCommentI[] = await Api.reportCardComment.getComments((classroom?._id ?? ""), reportCardTerm);
        console.log("_comments:", _comments);
        const _map: StudentReportMap = {};
        for (const comment of _comments) {
          _map[comment.student.toString()] = comment;
        }

        setStudents(_students);
        setStudentReportMap(_map);
      }
      catch (error) {
        console.log(error);
      }
    }

    fetchStudents();
  }, [classroom, reportCardTerm]);

  const navigate = useNavigate();

  function getStatus(student: any): ReportCardCommentStatus {
    return studentReportMap[student._id] ?
      { status: "Started", color: "#FFFFFF" } :
      { status: "Not Started", color: "#FFFFFF" }
  }

  async function editComment(student: StudentI) {
    try {
      const _comment = await Api.reportCardComment.getOrCreate(classroom, reportCardTerm, student);
      navigate("/student-comment/" + _comment._id);
    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>First Name</TableCell>
            <TableCell>Last Name</TableCell>
            <TableCell align="center">Status</TableCell>
            {/* <TableCell align="center">View</TableCell> */}
            <TableCell align="center">Edit</TableCell>
            <TableCell align="center">Delete</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {students.map((student: StudentI) => (
            <TableRow
              key={student.firstName + student.lastName}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">{student.firstName}</TableCell>
              <TableCell align="left">{student.lastName}</TableCell>
              <TableCell align="center">{getStatus(student).status}</TableCell>
              {/* <TableCell align="center"><IconButton><VisibilityIcon /></IconButton></TableCell> */}
              <TableCell align="center"><IconButton onClick={() => editComment(student)}><EditIcon /></IconButton></TableCell>
              <TableCell align="center"><IconButton><DeleteIcon /></IconButton></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

// export default ClassroomPage;
// VisibilityIcon