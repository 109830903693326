import { Link } from 'react-router-dom';
import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card, TextField } from '@mui/material';
import { Button } from '@material-ui/core';

import { useNavigate } from 'react-router-dom';

import { makeStyles } from "@material-ui/core/styles";
import { useAuth } from '../components/Auth';
import Nav from '../components/Nav';

const useStyles = makeStyles((theme) => ({
  textfield: {
    "& .MuiFilledInput-root": {
      background: "#FCFDFE"
    }
  }
}));

function SignupPage() {
  const [error, setError] = React.useState("");
  const [, setLoading] = React.useState(true);
  const [firstName, setFirstName] = React.useState("");
  const [lastName, setLastName] = React.useState("");
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const navigate = useNavigate();
  const { signup } = useAuth();

  React.useEffect(() => {
    setLoading(false);
  }, [])

  const onFirstNameChanged = (ev: React.ChangeEvent<HTMLInputElement>): void => setFirstName(ev.target.value)
  const onLastNameChanged = (ev: React.ChangeEvent<HTMLInputElement>): void => setLastName(ev.target.value)
  const onEmailChanged = (ev: React.ChangeEvent<HTMLInputElement>): void => setEmail(ev.target.value)
  const onPasswordChanged = (ev: React.ChangeEvent<HTMLInputElement>): void => setPassword(ev.target.value)

  const onSignup = async () => {
    // TODO: call login api
    try {
      // setError("");
      // const user = await Api.auth.login(email, password);
      // setUser(user);
      // localStorage.setItem("user", JSON.stringify(user));
      await signup(email, password, firstName, lastName);
      navigate('/classroom');
    }
    catch (err: any) {
      try {
        const message: string | undefined = err.response.data;
        console.log(message);
        if (message)
          setError(message);
        else
          setError("error loging in");
      } catch (error) { }
    }
  }

  const classes = useStyles();

  return (
    // <Container style={{ backgroundColor: "black" }}>
    <Box width="100vw" minHeight="100vh" bgcolor={"#363740"} display="flex" flexDirection="column" alignContent="center" justifyContent="center" alignItems={"center"} textAlign={"center"}>
      <Nav />
      <Box width="350px" paddingTop="70px">
        <Card>
          <Box padding="35px" height={"460px"} display="flex" flexDirection="column" alignContent="center" justifyContent="center" alignItems={"center"} textAlign={"center"}>
            <Typography fontSize={19} fontWeight={500} color={"#A4A6B3"}> Teacher Dashboard </Typography>
            <Typography fontSize={24} fontWeight={500} color={"#252733"}> Sign up for Teacher Dashboard </Typography>
            <Typography fontSize={14} color={"#9FA2B4"}> Enter your name, email, and password below </Typography>
            <Box flexGrow={1} />

            {/* Text fields */}
            <Box display="flex" flexDirection="column" width="100%" alignContent="center" justifyContent="center">
              <TextField className={classes.textfield} label="First Name" variant="outlined" size="small" fullWidth={true} margin="dense" onChange={onFirstNameChanged} value={firstName} />
              <TextField className={classes.textfield} label="Last Name" variant="outlined" size="small" fullWidth={true} margin="dense" onChange={onLastNameChanged} value={lastName} />
              <TextField className={classes.textfield} label="Email" variant="outlined" size="small" fullWidth={true} margin="dense" onChange={onEmailChanged} value={email} />
              <TextField label="Password" type="password" variant="outlined" size="small" fullWidth={true} margin="dense" onChange={onPasswordChanged} value={password} />
              <Box paddingTop="16px" width="100%">
                <Button fullWidth variant="contained" style={{ backgroundColor: "#3751FF", textTransform: "none", height: "42px", boxShadow: "0px 4px 12px 0px #3751FF3D" }} onClick={onSignup}>
                  <Typography color="white" fontSize={14}>
                    Sign up
                  </Typography>
                </Button>
              </Box>
              {error ? <Typography paddingTop="20px" color="red">{error}</Typography> : <Box />}
            </Box>

            <Box flexGrow={1} />
            <Typography paddingRight="5px" fontSize={14} color={"#9FA2B4"}>Already have an account? <Link to="/login" style={{ color: '#3751FF', textDecoration: "none" }}>Log in</Link></Typography>
            <Box flexGrow={1} />
          </Box >
        </Card>
      </Box>
    </Box>
    // </Container>
  );
}

export default SignupPage;