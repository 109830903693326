import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, Card, Divider, IconButton, List, ListItem, ListItemIcon, ListItemText, MenuItem, TextField } from '@mui/material';
import { DashboardDrawer } from './DashboardDrawer';
import Select, { SelectChangeEvent } from "@mui/material/Select";
import DeleteIcon from '@mui/icons-material/Delete';
import Api from "../../Api";
import { ClassroomI, StudentI, StudentId } from "../../interfaces/interfaces";

const Classes: ClassroomI[] = [];

const SchoolYear = [
  "2019/2020",
  "2020/2021",
  "2021/2022",
]

function ClassroomPage() {

  const [, setLoading] = React.useState(true);
  const [classrooms, setClassrooms] = React.useState<ClassroomI[]>(Classes);

  const [classroom, setClassroom] = React.useState<ClassroomI>();
  const [classroomName, setClassroomName] = React.useState<string>("");
  const [newClassroomName, setNewClassroomName] = React.useState("");
  const [selectedSchoolYear, setSelectedSchoolYear] = React.useState(SchoolYear[SchoolYear.length - 1]);
  const [newStudentFirstName, setNewStudentFirstName] = React.useState("");
  const [newStudentLastName, setNewStudentLastName] = React.useState("");

  const [students, setStudents] = React.useState<StudentI[]>([]);


  React.useEffect(() => {
    async function fetchStudents() {
      try {
        console.log("classroom changed");
        if (!classroom) return setStudents([]);
        setClassroomName(classroom.name);
        const _students: StudentI[] = await Api.classroom.getStudents(classroom._id ?? "");
        console.log(classroom._id ?? "");
        console.log(_students);
        setStudents(_students);
      }
      catch (error) {
        console.log(error);
      }
    }

    fetchStudents();
    setLoading(false);
  }, [classroom]);

  React.useEffect(() => {
    async function fetchClassrooms() {
      try {
        const _classrooms: ClassroomI[] = await Api.classroom.getClassrooms(selectedSchoolYear);
        setClassrooms(_classrooms);
        if (_classrooms.length === 0) setStudents([]);

      }
      catch (error) {
        console.log(error);
      }
    }

    fetchClassrooms();
    setLoading(false);
  }, [selectedSchoolYear]);

  React.useEffect(() => {
    // console.log("classrooms - useEffect");
    if (!classrooms) return;
    if (classrooms.length <= 0) return;

    setClassroom(classrooms[0]);
    // setClassroomName(classrooms[0]?.name);
  }, [classrooms]);



  async function createClassroom() {
    try {
      await Api.classroom.create(newClassroomName, selectedSchoolYear);
      setNewClassroomName("");

      const _classrooms: ClassroomI[] = await Api.classroom.getClassrooms(selectedSchoolYear);
      setClassrooms(_classrooms);
    }
    catch (error) {

    }
  }

  async function addStudent() {
    try {
      if (!classroom) return; // TODO set new student error message ("Select a class to add a student");
      await Api.classroom.addStudent(classroom._id ?? "", newStudentFirstName, newStudentLastName);
      setNewStudentFirstName("");
      setNewStudentLastName("");

      const _students: StudentI[] = await Api.classroom.getStudents(classroom._id ?? "");
      setStudents(_students);
    }
    catch (error) {
      console.log(error);
    }
  }

  async function deleteStudent(student: StudentI) {
    try {
      if (!classroom) return; // TODO set new student error message ("Select a class to add a student");
      const _deletedStudent = await Api.classroom.deleteStudent(student._id ?? "");
      console.log("deleted student", _deletedStudent);

      const _students: StudentI[] = await Api.classroom.getStudents(classroom._id ?? "");
      console.log(_students);
      setStudents(_students);
    }
    catch (error) {
      console.log(error);
    }
  }

  function handleChange(event: SelectChangeEvent) {
    //set selection to the value selected
    setClassroomName(event.target.value);
  }

  function onSchoolYearChanged(event: SelectChangeEvent) {
    setSelectedSchoolYear(event.target.value);
  }
  function onFirstNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setNewStudentFirstName(event.target.value);
  }
  function onLastNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setNewStudentLastName(event.target.value);
  }


  function onNewClassroomNameChanged(event: React.ChangeEvent<HTMLInputElement>) {
    //set selection to the value selected
    setNewClassroomName(event.target.value);
  }

  // if (loading) return (
  //   <div>
  //     <Nav />
  //     Loading ...
  //   </div>
  // );


  return (

    <Box
      width="100vw" height="100vh"
      bgcolor={"#F7F8FC"}
      display="flex" flexDirection="row"
    >
      {/* <Nav /> */}

      <DashboardDrawer selected='Classroom' />

      <Box flexGrow={1} display="flex" flexDirection={"column"} padding="30px">
        <Typography fontWeight={500} fontSize={24} paddingBottom="15px">Classroom</Typography>
        <Typography fontWeight={500} fontSize={19} >Select a School Year</Typography>
        <Select
          value={selectedSchoolYear}
          onChange={onSchoolYearChanged}
          size="small"
          style={{ width: "160px", backgroundColor: "white" }}
        >
          {SchoolYear.map((value) => {
            return <MenuItem key={value} value={value} > {value} </MenuItem>
          })}
        </Select>

        <Box flexGrow={1} paddingTop="15px">
          <Card style={{ width: "100%", height: "100%" }} elevation={0} variant="outlined">
            <Box padding="30px">

              {/* Select A classroom title and drop down menu */}
              <Typography fontWeight={500} fontSize={19} paddingBottom="10px">Select a Classroom</Typography>
              <Select
                value={classroomName}
                onChange={handleChange}
                size="small"
                style={{ width: "160px" }}
              >
                {
                  classrooms.map((classroom: ClassroomI) => {
                    return <MenuItem
                      key={classroom.name}
                      value={classroom.name}
                      onClick={
                        () => { setClassroom(classroom); console.log(classroom); }
                      }

                    >
                      {classroom.name}
                    </MenuItem>
                  })
                }
              </Select>

              {/* Add New Classroom textfield and button */}
              <Box display="flex" flexDirection={"row"} alignItems={"center"} paddingTop="10px">
                <Box width={"160px"}>
                  <TextField label="Classroom Name" type="text" variant="outlined" size="small" fullWidth={true} margin="dense" onChange={onNewClassroomNameChanged} value={newClassroomName} />
                </Box>
                <Box width={"160px"} paddingTop="4px" paddingLeft="10px">
                  <Button fullWidth variant="contained" style={{ backgroundColor: "#3751FF", textTransform: "none", height: "40px" }} onClick={createClassroom}>
                    <Typography color="white" fontSize={12}>Add New Classroom</Typography>
                  </Button>
                </Box>
              </Box>

              {/* Students - Add a Student Field and Student list */}
              <Typography fontWeight={500} fontSize={19} paddingTop="30px">Students</Typography>
              <Box display="flex" flexDirection={"row"} alignItems={"center"} paddingBottom="10px">
                <Box width={"160px"}>
                  <TextField label="First Name" variant="outlined" size="small" fullWidth={true} margin="dense" onChange={onFirstNameChanged} value={newStudentFirstName} />
                </Box>
                <Box width={"160px"} paddingLeft={"10px"}>
                  <TextField label="Last Name" variant="outlined" size="small" fullWidth={true} margin="dense" onChange={onLastNameChanged} value={newStudentLastName} />
                </Box>

                {/* Button */}
                <Box width={"160px"} paddingTop="4px" paddingLeft="10px">
                  <Button fullWidth variant="contained" style={{ backgroundColor: "#3751FF", textTransform: "none", height: "40px" }} onClick={addStudent}>
                    <Typography color="white" fontSize={12}>Add New Student</Typography>
                  </Button>
                </Box>
              </Box>
            </Box>

            <Box width="100%">
              {/* Student List */}
              <Divider />
              <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                {students.map((student) => {
                  return (
                    <ListItem
                      style={{ width: "100%" }}
                      key={student._id}
                      // secondaryAction={

                      // }
                      divider={true}
                    // disablePadding
                    >
                      {/* <ListItemButton role={undefined} onClick={()=>{}} dense> */}
                      <ListItemIcon />

                      <ListItemText primary={`${student.firstName} ${student.lastName}`} />
                      {/* <IconButton edge="end" aria-label="comments" onClick={() => console.log(student)}> */}
                      <IconButton edge="end" aria-label="comments" onClick={() => deleteStudent(student)}>
                        <DeleteIcon />
                      </IconButton>
                      {/* </ListItemButton> */}
                    </ListItem>
                  );
                })}

              </List>
            </Box>
          </Card>
        </Box>

      </Box>
    </Box>
  );
}

export default ClassroomPage;