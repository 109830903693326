import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { ButtonBase, Card, Grid, MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { DashboardDrawer } from '../DashboardDrawer';
import React from 'react';
import { useNavigate } from 'react-router-dom';

const Grades = [
  "JK",
  "SK",
  "1",
  "2",
  "3",
  "4",
  "5",
  "6",
  "7",
  "8",
];

interface Subject {
  name: string,
}

const Resources: { [key: string]: Subject[] } = {
  "JK": [],
  "SK": [],
  "1": [],
  "2": [],
  "3": [],
  "4": [],
  "5": [],
  "6": [],
  "7": [
    { name: "Science" },
    { name: "English" },
    { name: "History" },
    { name: "Math" },
    { name: "Geography" },
    { name: "French" },
    { name: "Art" },
    { name: "Health and Phys-Ed" },
  ],
  "8": [],
}

export function ResourcesPage() {
  const [grade, setGrade] = React.useState(Grades[Grades.length - 2]);
  const [subjects, setSubjects] = React.useState(Resources[grade]);
  const navigate = useNavigate();

  function onGradeChanged(event: SelectChangeEvent) {
    setGrade(event.target.value);
    setSubjects(Resources[event.target.value]);
  }

  return (
    <Box
      width="100vw" height="100vh"
      bgcolor={"#F7F8FC"}
      display="flex" flexDirection="row"
    >
      {/* <Nav /> */}

      <DashboardDrawer selected='Resources' />
      <Box flexGrow={1} display="flex" flexDirection={"column"} padding="30px">
        <Typography fontWeight={500} fontSize={24} paddingBottom="15px">Resources</Typography>

        <Box width="160px">
          <Typography fontWeight={500} fontSize={19} >Grade</Typography>
          <Select
            value={grade}
            onChange={onGradeChanged}
            size="small"
            style={{ width: "160px", minWidth: "100%", backgroundColor: "white" }}
          >
            {Grades.map((value) => {
              return <MenuItem key={value} value={value} > {value} </MenuItem>
            })}
          </Select>
        </Box>

        <Box flexGrow={1} paddingTop="30px">
          {/* <Card style={{ width: "100%", height: "100%" }} elevation={0} variant="outlined"> */}
          <Box>
            <Typography fontWeight={500} fontSize={19} >Subjects</Typography>

            <Grid container spacing={2} maxWidth={"500px"} minWidth={"500px"}>
              {
                subjects.map((subject: Subject) => {
                  return (
                    <Grid item xs={5} key={subject.name}>
                      <Card style={{ width: "180px" }}>
                        <ButtonBase
                          style={{ width: "100%" }}
                          onClick={() => { navigate("/resources/7/science"); }}
                        >
                          <Box padding="15px">
                            <Typography textAlign={"center"} fontWeight={500}>
                              {subject.name}
                            </Typography>
                          </Box>
                        </ButtonBase>
                      </Card>
                    </Grid>
                  );
                })
              }
            </Grid>

          </Box>
          {/* </Card> */}
        </Box>
      </Box>
    </Box>
  );
}

