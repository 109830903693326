import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Chip, Grid, } from '@mui/material';
import { DashboardDrawer } from '../DashboardDrawer';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';

interface Unit { unit: string, name: string };
interface Duration { name: string };

interface Subject {
  name: string,
  requirements: Unit[]
}

const Resources: { [key: string]: Subject[] } = {
  "JK": [],
  "SK": [],
  "1": [],
  "2": [],
  "3": [],
  "4": [],
  "5": [],
  "6": [],
  "7": [
    {
      name: "Science",
      requirements: [
        {
          unit: "Understanding Life Systems",
          name: "Life Systems",
        },
        {
          unit: "Understanding Structures and Mechanisms",
          name: "Structures and Mechanisms",
        },
        {
          unit: "Understanding Matter and Energy",
          name: "Matter and Energy",
        },
        {
          unit: "Understanding Earth and Space Systems",
          name: "Earth and Space Systems",
        },
      ]
    },

  ],
  "8": [],
}
const durations: Duration[] = [
  { name: "Short" },
  { name: "Medium" },
  { name: "Long" },
];

export function SubjectPlanningPage() {
  const [searchUnits, setSearchUnits] = React.useState<Unit[]>([]);
  const [searchDurations, setSearchDurations] = React.useState<Duration[]>([]);

  function toggleUnit(unit: Unit) {
    if (searchUnits.includes(unit)) {
      const _searchUnits = searchUnits.filter((value) => value !== unit);
      setSearchUnits(_searchUnits);
    } else {
      setSearchUnits([...searchUnits, unit]);
    }
  }

  function toggleDuration(duration: Duration) {
    console.log(duration);
    console.log(searchDurations);
    if (searchDurations.includes(duration)) {
      const _searchDurations = searchDurations.filter((value) => value !== duration);
      setSearchDurations(_searchDurations);
    } else {
      setSearchDurations([...searchDurations, duration]);
    }
  }

  const SUBJECT = "Science";


  return (

    <Box
      width="100vw" height="100vh"
      bgcolor={"#F7F8FC"}
      display="flex" flexDirection="row"
    >
      {/* <Nav /> */}
      <DashboardDrawer selected='Resources' />
      <Box flexGrow={1} display="flex" flexDirection={"column"} padding="30px">
        <Typography fontWeight={500} fontSize={24} paddingBottom="15px">{SUBJECT}</Typography>

        <Box paddingTop="30px" display="flex" flexDirection="row">
          {/* <Card style={{ width: "100%", height: "100%" }} elevation={0} variant="outlined"> */}
          <Box>
            <Typography fontWeight={500} fontSize={20} >Requirements</Typography>
            {
              Resources["7"][0].requirements.map((requirement) => {
                return (
                  <Box display="flex" flexDirection="row" padding="5px" paddingLeft="15px" key={requirement.unit}>
                    <CheckIcon color="success" /> <Typography paddingLeft="10px">{requirement.unit}</Typography>
                  </Box>)
              })
            }
          </Box>

          {/* Search Filters */}
          <Box flexGrow={1} paddingLeft="50px">
            <Typography fontWeight={500} fontSize={20} >Search Filters</Typography>
            <Box paddingTop="15px">
              <Typography fontWeight={500} fontSize={20} >Units:</Typography>
              <Grid container>
                {
                  Resources["7"][0].requirements.map((requirement) => {
                    return (
                      <Grid item padding="5px" key={requirement.unit}>
                        <Chip
                          label={requirement.name}
                          onClick={() => { toggleUnit(requirement) }}
                          variant={searchUnits.includes(requirement) ? "filled" : "outlined"}
                        />
                      </Grid>
                    )
                  })
                }
              </Grid>

              <Typography fontWeight={500} fontSize={20} paddingTop="15px">Unit Duration:</Typography>
              <Grid container>

                {
                  durations.map((duration) => {
                    return (
                      <Grid item padding="5px" key={duration.name} >
                        <Chip
                          label={duration.name}
                          onClick={() => { toggleDuration(duration) }}
                          variant={searchDurations.includes(duration) ? "filled" : "outlined"}
                        />
                      </Grid>
                    )
                  })
                }
              </Grid>
            </Box>
          </Box>
          {/* </Card> */}
        </Box>
      </Box>
    </Box>
  );
}

