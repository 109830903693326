import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Card } from '@mui/material';
import { DashboardDrawer } from './DashboardDrawer';
import Nav from '../../components/Nav';


export function PlanningPage() {

  return (

    <Box
      width="100vw" height="100vh"
      bgcolor={"#F7F8FC"}
      display="flex" flexDirection="row"
    >
      {/* <Nav /> */}
      <DashboardDrawer selected='Planning' />
      <Box flexGrow={1} display="flex" flexDirection={"column"} padding="30px">
        <Typography fontWeight={500} fontSize={24} paddingBottom="15px">Planning</Typography>
        <Box flexGrow={1} paddingTop="15px">
          <Card style={{ width: "100%", height: "100%" }} elevation={0} variant="outlined">
            <Box padding="30px">

            </Box>
          </Card>
        </Box>
      </Box>
    </Box>
  );
}

