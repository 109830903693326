import { BrowserRouter, Routes, Route } from 'react-router-dom';

import LoginPage from './pages/LoginPage';
import PrivacyPolicyPage from './pages/PrivacyPolicyPage';
import ClassroomPage from './pages/dashboard/ClassroomPage';
import { ReportCardCommentsPage } from './pages/dashboard/report-card-comments/ReportCardCommentsPage';
import { PlanningPage } from './pages/dashboard/PlanningPage';
import { ResourcesPage } from './pages/dashboard/resources/ResourcesPage';
import SignupPage from './pages/SignupPage';
import LandingPage from './pages/LandingPage';
import { StudentCommentPage } from './pages/dashboard/report-card-comments/StudentCommentPage';
import { SubjectPlanningPage } from './pages/dashboard/resources/SubjectPlanningPage';
import { AuthProvider } from './components/Auth';
import ProtectedRoute from './components/ProtectedRoute';
import React from 'react';
import { TeacherI } from './interfaces/interfaces';
import Api from './Api';
import { Box } from '@mui/material';
import Nav from './components/Nav';
import { CookiesProvider } from 'react-cookie';


function AppRoutes() {
  // const { teacher, loginFromCookies } = useAuth();
  // const [teacher, setTeacher] = React.useState<TeacherI | null>(null);

  return (
    <Box>
      <BrowserRouter>
        <Routes>
          <Route path="/login/" element={<LoginPage />} />
          <Route path="/signup/" element={<SignupPage />} />
          <Route path="/privacy/" element={<PrivacyPolicyPage />} />

          {/* Dashboard */}
          <Route path="/classroom/" element={<ProtectedRoute component={ClassroomPage} />} />
          <Route path="/report-card-comments/" element={<ProtectedRoute component={ReportCardCommentsPage} />} />
          <Route path="/student-comment/:id" element={<ProtectedRoute component={StudentCommentPage} />} />
          <Route path="/resources/" element={<ProtectedRoute component={ResourcesPage} />} />
          <Route path="/resources/:grade/:subject" element={<ProtectedRoute component={SubjectPlanningPage} />} />
          <Route path="/planning/" element={<ProtectedRoute component={PlanningPage} />} />

          <Route path="*" element={<LandingPage />} />
        </Routes>
      </BrowserRouter>
    </Box>
  );
}

function App() {
  const [teacher, setTeacher] = React.useState<TeacherI | null>(null);
  async function loginFromCookies() {
    try {
      const _teacher = await Api.user.me();
      setTeacher(_teacher);
    }
    catch (error) { }
  }

  React.useEffect(() => {
    loginFromCookies();
  }, [])

  return (
    <CookiesProvider>
      <AuthProvider defaultTeacher={teacher}>
        <AppRoutes />
      </AuthProvider>
    </CookiesProvider>
  );
}

export default App;