import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { useNavigate } from 'react-router-dom';
import { Button, Card, Container } from '@mui/material';
import Nav from '../components/Nav';


function LandingPage() {
  const navigate = useNavigate();

  function goto(link: string) {
    navigate(link);
  }

  return (
    // <Container style={{ backgroundColor: "black" }}>
    // <Box minWidth="100vw" minHeight="100vh" bgcolor={"#363740"} display="flex" flexDirection="column" alignContent="center" justifyContent="center" alignItems={"center"} textAlign={"center"}>
    <Box bgcolor={"#D1DCE5"}>
      <Nav />
      <Container>
        <Box display="flex" flexDirection="row" width="100%" minHeight="100vh">
          <Box flexGrow={1} padding="30px" paddingTop="100px">
            <Typography fontSize={60} fontWeight={500} paddingBottom={0} lineHeight={1}>Teacher </Typography>
            <Typography fontSize={60} fontWeight={500} paddingTop={0} lineHeight={1}>Dashboard</Typography>
            <Typography fontSize={38} fontWeight={500} lineHeight={1} paddingTop="50px">
              Canada’s Best Online Report Card Comment Generator
              And class content provider
            </Typography>

            {/* Login and Signup buttons */}
            <Box display="flex" flexDirection={"row"} paddingTop="20px">
              <Box paddingTop="16px" width="120px">
                <Button fullWidth variant="contained" style={{ backgroundColor: "#3751FF", textTransform: "none", height: "42px" }} onClick={() => { goto("/login") }}>
                  <Typography color="white" fontSize={16} fontWeight={500}>
                    Log In
                  </Typography>
                </Button>
              </Box>
              <Box paddingTop="16px" width="120px" paddingLeft={"16px"}>
                <Button fullWidth variant="contained" style={{ backgroundColor: "#3751FF", textTransform: "none", height: "42px" }} onClick={() => { goto("/signup") }}>
                  <Typography color="white" fontSize={16} fontWeight={500}>
                    Sign up
                  </Typography>
                </Button>
              </Box>
            </Box>
          </Box>
          <Box flexGrow={1} minWidth={"50%"}>

          </Box>
        </Box>
      </Container>

      <Box minWidth="100vw" minHeight="100vh" bgcolor={"#F7F8FC"}>
        <Container>
          <Box padding="30px" paddingTop="100px" display="flex" flexDirection={"column"} alignItems={"center"}>
            <Typography fontSize={38} fontWeight={500} paddingBottom={0} lineHeight={1}>Easily Generate Report Card Comments </Typography>

            <Box width="100%" paddingTop="50px" display="flex" flexDirection={"row"} justifyContent={"space-between"}>
              <Card style={{ width: "300px", height: "300px" }} sx={{ boxShadow: "10px 10px 50px 3px #275C8D1A" }}>
                <Box padding="30px">
                  <Typography fontSize={28} fontWeight={500}>Save Time</Typography>
                  <Typography fontSize={18} paddingTop="25px">Create your report card learning skills comments for each student in under 3 minutes.</Typography>
                </Box>
              </Card>
              <Card style={{ width: "300px", height: "300px" }} sx={{ boxShadow: "10px 10px 50px 3px #275C8D1A" }}>
                <Box padding="30px">
                  <Typography fontSize={28} fontWeight={500}>Easily Assess students</Typography>
                  <Typography fontSize={18} paddingTop="25px">The Student Evaluator tool can create assessments of your students for your report cards and for your parent/teacher meetings.</Typography>
                </Box>
              </Card>
              <Card style={{ width: "300px", height: "300px" }} sx={{ boxShadow: "10px 10px 50px 3px #275C8D1A" }}>
                <Box padding="30px">
                  <Typography fontSize={28} fontWeight={500}>Generate Comment and Learning Skill </Typography>
                  <Typography fontSize={18} paddingTop="25px">The Student Evaluator tool will generate a report card comment and a overall mark for each learning skill.</Typography>
                </Box>
              </Card>
            </Box>
          </Box>
        </Container>

      </Box>

    </Box>
    // </Container>
  );
}

export default LandingPage;