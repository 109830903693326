import React from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Divider, Drawer, List, ListItem, ListItemIcon } from '@mui/material';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import SettingsIcon from '@mui/icons-material/Settings';
import CommentIcon from '@mui/icons-material/Comment';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import SupervisorAccountIcon from '@mui/icons-material/SupervisorAccount';
import CreateIcon from '@mui/icons-material/Create';
import Logo from "../../assets/logo.svg";
import LogoutIcon from '@mui/icons-material/Logout';

import { styled } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../components/Auth";

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  // padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const Pages = [
  {
    text: 'Classroom',
    icon: <SupervisorAccountIcon style={{ color: "#9FA2B4" }} />,
    link: '/classroom',
  },
  {
    text: 'Report Card Comments',
    icon: <CommentIcon style={{ color: "#9FA2B4" }} />,
    link: "/report-card-comments",
  },
  {
    text: 'Resources',
    icon: <LightbulbIcon style={{ color: "#9FA2B4" }} />,
    link: "/resources",
  },
  {
    text: 'Planning',
    icon: <CreateIcon style={{ color: "#9FA2B4" }} />,
    link: '/planning',
  },
];


export const DashboardDrawer: React.FC<{ selected: string }> = ({ selected }) => {
  const { teacher, logout } = useAuth();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate("/");
  };

  function goto(link: string) {
    navigate({ pathname: link })
  }

  return (
    <Drawer
      sx={{
        width: 250,
        // flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: 250,
          boxSizing: 'border-box',
          backgroundColor: "#363740"
        },
      }}
      variant="persistent"
      anchor="left"
      open={true}
    >
      <DrawerHeader>
        <Box component="img" sx={{ height: 28 }} alt="Logo" src={Logo} />
        <Typography fontSize={18} fontWeight={500} color="#A4A6B3" paddingLeft="8px">
          Teacher Dashboard
        </Typography>
      </DrawerHeader>

      <List>
        {
          Pages.map((page, index) => (
            <ListItem key={page.text} button onClick={() => goto(page.link)} style={{ backgroundColor: page.text === selected ? "#43454F" : "#363740" }}>
              <ListItemIcon>
                {page.icon}
              </ListItemIcon>
              <Typography color={page.text === selected ? "#DDE2FF" : "#A4A6B3"} fontSize={16}>
                {page.text}
              </Typography>
            </ListItem>
          ))
        }
        <Box height={25} />
      </List>
      <Divider style={{ background: "#4B4C55" }} />
      <List>
        {[
          { text: 'Settings', icon: <SettingsIcon style={{ color: "#9FA2B4" }} /> },
          { text: 'Subscription', icon: <InboxIcon style={{ color: "#9FA2B4" }} /> }
        ].map((item, index) => (
          <Box key={item.text} bgcolor={item.text === selected ? "#43454F" : "#363740"}>

            <ListItem button >
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <Typography color={item.text === selected ? "#DDE2FF" : "#A4A6B3"} fontSize={16}>
                {item.text}
              </Typography>
            </ListItem>
          </Box>
        ))}

        <ListItem button onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon style={{ color: "#9FA2B4" }} />
          </ListItemIcon>
          <Typography color={"#A4A6B3"} fontSize={16}>
            Logout
          </Typography>
        </ListItem>
      </List>
    </Drawer>
  );
}