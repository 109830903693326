// import { Redirect, Route, RouteProps } from 'react-router';
// import { Box } from '@mui/material';
// import { RouteProps, Routes, Route, useNavigate } from 'react-router-dom';

import { FC } from 'react';
import { Navigate } from 'react-router-dom';
import React from 'react';
import Api from '../Api';
import { TeacherI } from '../interfaces/interfaces';
import { Box } from '@mui/material';

interface PropType {
    component: React.FC;
}

const ProtectedRoute: FC<PropType> = ({ component: Component }) => {
  // const {teacher} = useAuth();
  const [teacher, setTeacher] = React.useState<TeacherI | null>(null);
  const [loading, setLoading] = React.useState(true);
  
  React.useEffect(() => {
    (
      async ()=>{
        try {
          const _teacher = await Api.user.me();
          setTeacher(_teacher);
          setLoading(false);
        }catch(error) {
          setLoading(false);
        }
      }
    )();
  }, [])

    if (loading) return <Box/>;
    if (teacher) return <Component />;
    return <Navigate to='/login' />;
};

export default ProtectedRoute;
