import axios from "axios";
import { ClassroomI, ReportCardCommentI, StudentI, TeacherI } from "./interfaces/interfaces";

axios.defaults.withCredentials = true;

const remoteURL = "https://teacherdashboard.ca/api";
const testingURL = "http://localhost:7001/api";
const TESTING = process.env.NODE_ENV === "development";
const baseURL = TESTING ? testingURL : remoteURL;

const Api = {

  user: {
    me: async (): Promise<TeacherI> => {
      const response = await axios.get(baseURL + "/teacher/me/");
      return response.data;
    },
  },

  auth: {
    login: async (email: string, password: string): Promise<TeacherI> => {
      const response = await axios.post(baseURL + "/auth/login/", { email, password });
      return response.data;
    },
    
    signup: async (email: string, password: string, firstName: string, lastName: string): Promise<TeacherI> => {
      const response = await axios.post(baseURL + "/auth/signup/", { email, password, firstName, lastName});
      return response.data;
    },
  },

  classroom: {
    create: async (name: string, schoolYear: string): Promise<ClassroomI> => {
      const response = await axios.post(baseURL + "/classroom/create/", { name, schoolYear });
      return response.data;
    },
    
    addStudent: async (classroomId: string, firstName: string, lastName: string): Promise<ClassroomI> => {
      const response = await axios.post(baseURL + "/classroom/add-student/"  + classroomId, { firstName, lastName });
      return response.data;
    },
    
    deleteStudent: async (studentId: string): Promise<StudentI> => {
      const response = await axios.delete(baseURL + "/classroom/delete/"  + studentId);
      return response.data;
    },
    
    getStudents: async (classroomId: string): Promise<StudentI[]> => {
      const response = await axios.get(baseURL + "/classroom/students/"  + classroomId);
      return response.data;
    },
    
    getClassrooms: async (schoolYear: string): Promise<ClassroomI[]> => {
      const response = await axios.post(baseURL + "/classroom/classrooms/", {schoolYear});
      return response.data;
    },
  },

  reportCardComment: {
    create: async (reportCardComment: ReportCardCommentI): Promise<ReportCardCommentI> => {
      const response = await axios.post(baseURL + "/report-card-comment/create/", reportCardComment);
      return response.data;
    },
    
    updateComment: async (reportCardComment: ReportCardCommentI, comment: string): Promise<ReportCardCommentI> => {
      const response = await axios.post(baseURL + "/report-card-comment/update-comment/"  + reportCardComment._id, { comment });
      return response.data;
    },
    
    getComments: async (classroomId: string, term: string): Promise<ReportCardCommentI[]> => {
      const response = await axios.get(baseURL + `/report-card-comment/comments/${classroomId}/${term}/`);
      return response.data;
    },
    
    getById: async (id: string): Promise<ReportCardCommentI> => {
      const response = await axios.get(baseURL + `/report-card-comment/id/${id}/`);
      return response.data;
    },
    
    getOrCreate: async (classroom: ClassroomI, term: String, studnet: StudentI): Promise<ReportCardCommentI> => {
      const response = await axios.post(baseURL + `/report-card-comment/get-or-create/`, {classroom: classroom._id, term, student: studnet._id});
      return response.data;
    },
    
  },


}

export default Api;