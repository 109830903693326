import React, { ChangeEvent, useState } from "react";
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { Button, ButtonBase, Card, Divider, IconButton, MenuItem, TextField } from '@mui/material';
import { DashboardDrawer } from '../DashboardDrawer';
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { Slider } from "@material-ui/core";
import InfoIcon from '@mui/icons-material/Info';
import Popover from '@mui/material/Popover';
import { useParams } from "react-router-dom";
import { ReportCardCommentI } from "../../../interfaces/interfaces";
import Api from "../../../Api";

const LearningSkills = [
  "Responsibility",
  "Organization",
  "Independent Work",
  "Collaboration",
  "Initiative",
  "Self Regulation",
];

const QuestionInfo: { [key: string]: any } = {
  "Responsibility":
    "Get homework, projects, class work and all assignments completed, and handed in on time? \n" +
    "Monitor and take responsibility for their own behaviour in school ?  \n" +
    "Take on roles of responsibility within the class and school environment?  \n" +
    "Complete work to the best of their ability and add necessary details?  \n",

  "Organization":
    "Keep their workspace and academic resources organized? " +
    "Actively seek out, and organize information from various sources to complete academic work? " +
    "Uses a daily agenda to manage time and establish academic priorities? " +
    "Gather the necessary tools and materials to complete their work? ",

  "Independent Work":
    "Listen carefully too instructions? " +
    "Work well without supervision and follow direct instructions? " +
    "Work consistently during class time on assigned activities? " +
    "Attempt to solve problems independently before asking for help? ",

  "Collaboration":
    "Work equally within group settings to actively solve problems? " +
    "Cooperate with others to solve problems and achieve group goals? " +
    "Share what they know with others, and listen to what everyone has to say to solve problems? " +
    "Establish good, quality relationships with their peers? ",

  "Initiative":
    "Actively participate in the learning process? " +
    "Look forward to learning and applying what they know? " +
    "Take risks in their learning and demonstrate innovative thinking? " +
    "Use checklists, success criteria or guidelines to complete their work? ",

  "Self Regulation":
    "Understand their academic strengths and needs? " +
    "Set academic goals and work hard towards achieving them? " +
    "Actively seek out assistance when needed, in order to understand concepts? " +
    "Use feedback from teachers and peers in order to improve the quality of their work? ",
}

const TemplateSentances: { [key: string]: any } = {
  "Responsibility": {
    "N": [
      "[name] rarely gets homework, projects, and assignments completed on time.",
      "[name] does not monitor or take responsibility for their behaviour in class.",
      "[name] does not take on roles of responsibility within the classroom or school environment.",
      "[name] does not complete work to the best of their ability.",
    ],
    "S": [
      "[name] sometimes gets homework, projects, and assignments completed on time.",
      "[name] sometimes takes responsibility for their behaviour in class.",
      "[name] sometimes takes on roles of responsibility within the classroom or school environment.",
      "[name] sometimes completes work to the best of their ability.",
    ],
    "G": [
      "[name] gets homework, projects, and assignments completed on time.",
      "[name] takes responsibility for their behaviour in class.",
      "[name] takes on roles of responsibility within the classroom or school environment.",
      "[name] completes work to the best of their ability.",
    ],
    "E": [
      "[name] always gets homework, projects, and assignments completed on time, or early.",
      "[name] always takes responsibility for their behaviour in class.",
      "[name] always takes on roles of responsibility within the classroom or school environment.",
      "[name] always completes work to the best of their ability.",
    ],
  },
  "Organization": {
    "N": [
      "[name] is not organized.",
    ],
    "S": [
      "[name] is kinda organized.",
    ],
    "G": [
      "[name] is organized.",
    ],
    "E": [
      "[name] is so fucking organized. SHEEEEEEESH!",
    ],
  },
  "Independent Work": {
    "N": [
      "[name] ??? Independent Work ??? YIKES",
    ],
    "S": [
      "[name] kinda sucks at Independent Work",

    ],
    "G": [
      "[name]'s Independent Work is kinda clean",

    ],
    "E": [
      "[name] absolutely deezed at Independent Work",
    ],
  },
  "Collaboration": {
    "N": [
      "[name] rarely gets homework, projects, and assignments completed on time.",
    ],
    "S": [
      "[name] sometimes gets homework, projects, and assignments completed on time.",

    ],
    "G": [
      "[name] gets homework, projects, and assignments completed on time.",

    ],
    "E": [
      "[name] always gets homework, projects, and assignments completed on time, or early.",
    ],
  },
  "Initiative": {
    "N": [
      "[name] rarely gets homework, projects, and assignments completed on time.",
    ],
    "S": [
      "[name] sometimes gets homework, projects, and assignments completed on time.",

    ],
    "G": [
      "[name] gets homework, projects, and assignments completed on time.",

    ],
    "E": [
      "[name] always gets homework, projects, and assignments completed on time, or early.",
    ],
  },
  "Self Regulation": {
    "N": [
      "[name] rarely gets homework, projects, and assignments completed on time.",
    ],
    "S": [
      "[name] sometimes gets homework, projects, and assignments completed on time.",

    ],
    "G": [
      "[name] gets homework, projects, and assignments completed on time.",

    ],
    "E": [
      "[name] always gets homework, projects, and assignments completed on time, or early.",
    ],
  },
}

const Ranks = ["N", "S", "G", "E"];
const Marks = [
  { label: "N", value: 0 },
  { label: "S", value: 1 },
  { label: "G", value: 2 },
  { label: "E", value: 3 },
];

export function StudentCommentPage() {

  const { id } = useParams();
  const [reportCardComment, setReportCardComment] = React.useState<ReportCardCommentI | null>();

  const [, setLoading] = React.useState(true);

  const [learningSkill, setLearningSkill] = React.useState(LearningSkills[0]);
  const [rank, setRank] = React.useState(2);
  const [comment, setComment] = React.useState("");
  const [suggestedComments, setSuggestedComments] = React.useState(TemplateSentances.Responsibility.G);
  const [info, setInfo] = React.useState(QuestionInfo["Responsibility"]);

  function updateSuggestedComments(rank: number, skill: string = learningSkill) {
    const suggestions: string[] = TemplateSentances[skill][Ranks[rank]];
    setSuggestedComments(suggestions);
  }

  React.useEffect(() => {
    setLoading(false);
  }, [])

  React.useEffect(() => {
    async function fetch() {
      if (!id) return Error("NO ID SPECIFIED");
      try {
        const _comment = await Api.reportCardComment.getById(id);
        setReportCardComment(_comment);
        setComment(_comment.comment);
      }
      catch (error) {
        console.log(error);
      }

      setLoading(false);
    }
    fetch();
  }, [id])

  function onLearningSkillChanged(event: SelectChangeEvent) {
    setLearningSkill(event.target.value);
    updateSuggestedComments(rank, event.target.value);
    setInfo(QuestionInfo[event.target.value]);
  }

  function onRankChanged(event: ChangeEvent<{}>, value: number | number[]) {
    setRank(value as number);
    updateSuggestedComments(value as number);
  }

  // Info popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const popoverId = open ? 'simple-popover' : undefined;


  // TODO design general dashboard loading page.
  // if (loading) return (
  //   <div>
  //     <Nav />
  //     Loading ...
  //   </div>
  // );


  return (

    <Box
      minWidth="100vw" minHeight="100vh"
      bgcolor={"#F7F8FC"}
      display="flex" flexDirection="row"
    >
      {/* <Nav /> */}
      <DashboardDrawer selected='Report Card Comments' />

      <Box flexGrow={1} display="flex" flexDirection={"column"} padding="30px">

        {/* Header - Back Button & Title */}
        <Box display="flex" flexDirection="row">
          <Box width={"100px"} paddingTop="4px" paddingRight="10px">
            <Button fullWidth variant="contained" style={{ backgroundColor: "#3751FF", textTransform: "none", height: "40px" }} onClick={() => { }}>
              <Typography color="white" fontSize={12}>Back</Typography>
            </Button>
          </Box>
          <Box>
            <Typography fontWeight={500} fontSize={24} paddingBottom="0px">Generate Student Comment</Typography>
            <Typography fontWeight={500} fontSize={18} paddingBottom="15px">2021/2022 - Progress Report - History</Typography>
          </Box>
        </Box>

        <Box flexGrow={1} paddingTop="15px">
          <Card style={{ width: "100%", height: "100%" }} elevation={0} variant="outlined">
            {/* Learning Skills selection section */}
            <Box padding="30px" minHeight="400px">

              {/* Learning Skill selection box and slider */}
              <Box>
                <Typography fontWeight={500} fontSize={19} >Learning Skill</Typography>
                <Box display="flex" flexDirection="row" alignItems={"center"}>
                  <Select
                    value={learningSkill}
                    onChange={onLearningSkillChanged}
                    size="small"
                    style={{ width: "160px", backgroundColor: "white" }}
                  >
                    {LearningSkills.map((value) => {
                      return <MenuItem key={value} value={value} > {value} </MenuItem>
                    })}
                  </Select>

                  {/* Learning Skill Slider */}
                  <Box width="200px" paddingLeft="20px">
                    <Slider value={rank} marks={Marks} max={3} onChange={onRankChanged} />
                  </Box>
                  <Box style={{ paddingLeft: "20px" }}>
                    <IconButton onClick={handleClick} >
                      <InfoIcon />
                    </IconButton>
                    <Popover
                      id={popoverId}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                    >
                      <Box style={{ width: "300px" }}>
                        <Typography sx={{ p: 2 }}>{info}</Typography>

                      </Box>
                    </Popover>
                  </Box>
                </Box>
              </Box>

              {/* <CommentSuggestion textTemplate="" comment={comment} setComment={setComment}/> */}
              {suggestedComments.map((text: string) => {
                return (
                  <Box padding="5px" key={text}>
                    <CommentSuggestion textTemplate={text} comment={comment} setComment={setComment} />
                  </Box>
                )
              })}
            </Box>

            <Box width={"100%"} minHeight="350px">
              {/* Student List */}
              <Divider />
              <Box padding="30px">
                <Typography fontWeight={500} fontSize={19} >Report Card Comment</Typography>
                {reportCardComment ?
                  <FinalComment comment={comment} setComment={setComment} reportCardComment={reportCardComment} />
                  : <Box> Loading </Box>
                }
              </Box>
            </Box>

          </Card>
        </Box>

      </Box>
    </Box>
  );
}

const CommentSuggestion: React.FC<{ textTemplate: string, comment: string, setComment: React.Dispatch<React.SetStateAction<string>> }> = ({ comment, textTemplate, setComment }) => {
  const [clicked, setClicked] = useState(false);
  const color: string = "gray";
  const selectedColor: string = "#3751FF";

  function onClicked() {
    setClicked(true);
    setComment(comment + " " + textTemplate);
  }

  return (
    <Card sx={{ width: "400px", border: '2px solid', borderColor: !clicked ? color : selectedColor }} >
      <ButtonBase
        onClick={onClicked}
        disabled={clicked}
      >
        <Box padding="15px">
          <Typography>
            {textTemplate}
          </Typography>
        </Box>
      </ButtonBase>
    </Card>
  );
}


const FinalComment: React.FC<{ reportCardComment: ReportCardCommentI, comment: string, setComment: React.Dispatch<React.SetStateAction<string>> }> = ({ comment, setComment, reportCardComment }) => {

  const [saved, setSaved] = React.useState(true);
  function onCommentChanged(event: React.ChangeEvent<HTMLInputElement>) {
    setSaved(false);
    setComment(event.target.value);
  }

  React.useEffect(() => {
    setSaved(false);
  }, [comment])

  async function save() {
    // TODO call api to update text;
    try {
      await Api.reportCardComment.updateComment(reportCardComment, comment);
      setSaved(true);

    }
    catch (error) {
      console.log(error);
    }
  }

  return (
    <Box width={"500px"}>
      <TextField
        variant="outlined"
        size="small"
        fullWidth={true}
        margin="dense"
        onChange={onCommentChanged}
        value={comment}
        multiline
        rows={10}
      />

      <Button fullWidth variant="contained" style={{ backgroundColor: saved ? "gray" : "#3751FF", textTransform: "none", height: "42px", boxShadow: "0px 4px 12px 0px #3751FF3D" }} onClick={save}>
        <Typography color="white" fontSize={14}>
          {saved ? "Saved" : "Save"}
        </Typography>
      </Button>
      {/* {error ? <Typography paddingTop="20px" color="red">{error}</Typography> : <Box />} */}
    </Box >
  );
}

// export default ClassroomPage;
// VisibilityIcon